

.chartMainTitle {
    width: 100%;
    display: block;

    text-align: center;
}

.chartTitleFlirt {
    width: 100%;
    display: block;

    font-size: 0.8em;
    color: var(--main-flirt);

    text-align: center;
}

.chartSubTitle {
    width: 50%;

    font-size: 0.75em;
}

.chartLeftTitle {
    float: left;
}

.chartRightTitle {
    float: right;
}

.chartSpacer {
    
    position: absolute;
    left: 50%;
    padding: 0 !important;

    width: 2px !important;
    height: 100%;
    background-color: #fff;
}

/* Details Section */
.chartDetailsSection {
    position: relative;

    
}

.chartContainer {
    height: 280px;
}

.sharedAxisChartWrapper {

}

/* Responsive styles */
@media screen and (max-width:768px) {
    .chartContainer {
        height: 200px;
    }

    .chartSubTitle {
        display: block;        
        width: 100%;
        margin-top: 20px;

        text-align: center;
    }
}

@media screen and (max-width:1024px) {
    .chartContainer {
        height: 250px;
    }
}