@import url('https://fonts.googleapis.com/css2?family=Oxanium:wght@200;600&display=swap');

:root {

  --light-shade: #F8f8f8;
  --light-accent: #8E8A89;

  --main-brand: #E24B2A;
  --main-flirt: #efff00;

  --dark-shade: #323237;
  --dark-accent: #7B7B7B;

  --main-brand-font: Oxanium;

  /* Overrides */
  --bs-body-bg: #000 !important;
  --bs-body-color: #fff !important;
}

a,
a:visited,
a:hover,
a:active {
  color: inherit;
  text-decoration: none !important;
}

a {
  color: #fff !important;
}

.App {
  text-align: center;
}

.head-section {
  min-height: 65vh;
  height: 65vh;

  /* background-image: linear-gradient(to bottom right, rgb(49, 49, 49), rgb(0, 0, 0)); */
  /* background-color: var(--dark-shade); */
}

.detail-section {
  height: 340px;


  position: relative;
}

/* Head Section Content */

/* Three Canvas wrapper */
.header-canvas-wrapper canvas {
  position: fixed;
}

.main-header-section {

  position: absolute;
  left: 1em;
  top: 2em;

}

.main-header-section__title {

  font-family: var(--main-brand-font);
  text-transform: uppercase;

  font-weight: 600;
  font-size: 4em;
  color: var(--light-shade);

}

.main-header-section__subtitle {

  /* font-family: var(--main-brand-font); */
  font-weight: 100;
  font-size: 2em;
  color: var(--light-shade);
}

@media only screen and (max-width: 768px) {
  .main-header-title {
    position: inherit;
  }
}

/* END Head Section Content */

/* Vertical Details Page */

.vertical-details-wrapper {}


.vertical-details-wrapper .title {
  text-align: left;
}

.vertical-details-wrapper h2,
.vertical-details-wrapper h3 {
  text-align: left !important;
}

.vertical-details-wrapper .section-content {
  text-align: left !important;
}

.vertical-details-wrapper .section-title {
  text-align: left;
}

.section-box .section-title {
  padding-bottom: 4px;

}

.company-first {
  font-weight: bold;
}

.company-second {
  font-weight: 100;
}

.section-box .section-content {

  padding-left: 50px;

}

/* 
.section-title__flirt {
  font-size: 0.6em;
  color: #e80203;
} */

/* END Vertical Details Page */

/* Details Section content */

.detail-section {

  /* background-image: url('../public/images/network-3597091.jpg'); */
}

.detail-section h1 {
  margin: 0px;
}

.details-section-content {
  min-height: 100%;
  background: rgba(0, 0, 0, 0.46);
  position: relative;
}

.details-section-content .details-item {
  width: 100%;
  height: 100%;

  /* background-image: linear-gradient(to bottom right, rgb(255, 255, 255), rgb(121, 121, 121)); */

}

.faded-center-container {
  /* background: rgb(2,0,36); */
  /* background: linear-gradient(90deg, rgba(2,0,36,0) 0%, rgba(0,0,0,1) 30%, rgba(0,2,2,1) 70%, rgba(0,0,0,0) 100%); */
  /* background: linear-gradient(90deg, rgba(2,0,36,0) 25%, rgba(0,0,0,1) 30%, rgba(0,2,2,1) 70%, rgba(0,0,0,0) 75%); */
  /* background: linear-gradient(90deg, rgba(0,0,0,0) 29%, rgba(0,0,0,1) 30%, rgba(0,2,2,1) 70%, rgba(0,0,0,0) 71%); */
}

.home-text-content {
  text-align: left;
}

.profile-card {
  width: 80%;

  border-radius: 60% 40% 40% 0%;

  overflow: hidden;
  padding-bottom: 80%;
  box-shadow: 0 16px 38px -12px rgb(0 0 0 / 56%), 0 4px 25px 0px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%);

  background-size: cover;

  background-position: center;
  background-image: url("./assets/images/shaun_profile.jpg");
}

.profile-card__image--home {
  width: 100%;
  height: 100%;

  background-size: cover;
  background-position: center;
  /* background-image: url("./assets/images/shaun_profile.jpg"); */
}

/* END Details Section content */

.fade-wrapper {
  position: relative;
  top: 0px;
  left: 0px;

  min-height: 100%;
  height: 100%;
  width: 100%;
}

.fadeIn {
  animation: 0.25s fadeIn forwards;
}

.fadeOut {
  animation: 0.25s fadeOut forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate(-20px, 0);
  }

  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translate(0px, 0px);
  }

  to {
    transform: translate(-20px, 0);
    opacity: 0;
  }
}

/* Footer */

.footer {
  height: 250px;
  padding-top: 25px;

  font-family: var(--main-brand-font);
  background-color: var(--dark-shade);
}

/* END Footer */

/* Chart Utilities */

.chart-interactive-element {
  cursor: pointer;
}

.tick {
  font-size: 1.3em;
}

/* Utility Classes */

.light-shade {
  color: var(--light-shade);
}