
.chartDetails {

    border: 1px dotted #fff;

    min-width: 200px;
    min-height: 50px;

}

.chartDetailsTitle {
    font-size: 1.2em;

    font-weight: 700;
}

.chartDetailsValue {
    color:var(--main-brand);
}